/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getSalesByProductCategory } from "../../Api/Dashboard/mutations";
import Table from "../../Blocks/Table/Table";
import { exportToExcel, formatDate } from "../../Common Functions/Function";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { top_selling_products_by_category_columns_name } from "./data";

const SalesProductCategory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [salesProductCategory, setSalesProductCategory] = useState<any[]>([]);
  const [dateError, setDateError] = useState("");
  const [startDate, setStartDate] = useState<Date | null | any>(null);
  const [endDate, setEndDate] = useState<Date | null | any>(null);
  const [isAPiCalled, setIsAPiCalled] = useState<boolean>(false);

  const { storeId } = useStoreId();

  useEffect(() => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const formatDate = (date: Date) =>
      `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;

    setStartDate(formatDate(startOfMonth));
    setEndDate(formatDate(currentDate));
    validateDates(
      formatDate(startOfMonth),
      formatDate(currentDate),
      setDateError,
      fetchSalesProductCategory
    );
  }, []);

  useEffect(() => {
    if (isAPiCalled) {
      validateDates(
        startDate,
        endDate,
        setDateError,
        fetchSalesProductCategory
      );
    }
  }, [startDate, endDate]);

  const validateDates = (
    start: string | number | Date,
    end: string | number | Date,
    setDateError: React.Dispatch<React.SetStateAction<string>>,
    fetchSalesProductCategory: (startDate: string, endDate: string) => void
  ) => {
    // If both dates are provided, check their validity
    if (start && end) {
      if (new Date(end) < new Date(start)) {
        setDateError(
          "End date must be greater than or equal to the start date."
        );
        return;
      } else {
        setDateError("");
        fetchSalesProductCategory(start as string, end as string);
      }
    } else {
      setDateError("");
      console.warn(
        "One or both dates missing, calling API to return all data."
      );
      fetchSalesProductCategory(start as string, end as string);
    }
  };

  const fetchSalesProductCategory = async (startDate: any, endDate: any) => {
    try {
      const data = await getSalesByProductCategory(storeId, startDate, endDate);
      setIsAPiCalled(true);
      const totalSales = data.reduce(
        (sum: any, item: { total_sales: { value: any } }) =>
          sum + item.total_sales.value,
        0
      );
      const categorySales = data.reduce(
        (
          acc: { [x: string]: any },
          item: {
            key: any;
            category: { categoryName: any };
            total_sales: { value: any };
          }
        ) => {
          const category = item.category.categoryName;
          const sales = item.total_sales.value;

          if (!acc[category]) {
            acc[category] = 0;
          }

          acc[category] += sales;
          return acc;
        },
        {}
      );
      const percentageSales = Object.entries(categorySales).map(
        ([category, sales]: any) => ({
          productCategory: category,
          totalSales: sales.toFixed(2), // Keep 2 decimal places
          percentageofSales: ((sales / totalSales) * 100).toFixed(2) + "%", // Percentage calculation
        })
      );

      setSalesProductCategory(percentageSales);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="row my-4 ">
        <div className="col-auto">
          <h3 className="theme_color mb-0 fw-bolder">
            {"Sales by Product Category"}
          </h3>
        </div>
        <div className="col-auto ms-auto">
          <button
            className="btn btn-outline-success"
            onClick={() => {
              exportToExcel(
                salesProductCategory,
                ["productCategory", "totalSales", "percentageofSales"],
                {
                  productCategory: "Product Category",
                  totalSales: "Total Sales (R)",
                  percentageofSales: "Percentage of Sales",
                },
                "sales_by_product_category_report"
              );
            }}
            type="button"
          >
            Export
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div className="col-sm-12 col-md-6 col-lg-5 col-xxl-4 mb-4">
          <label className="form-label" htmlFor="dateRange">
            Filter by date range
          </label>
          <div
            id="dateRange"
            className="d-flex flex-column flex-md-row justify-content-between"
          >
            <div className="form-group me-md-2 w-100">
              <label htmlFor="startDate">Start Date</label>
              <DatePicker
                selected={startDate}
                onChange={(date: any) =>
                  setStartDate(date ? formatDate(date) : null)
                }
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                className="form-control"
                aria-label="Start date"
                id="startDate"
                placeholderText="YYYY-MM-DD"
                isClearable
              />
            </div>
            <div className="form-group w-100">
              <label htmlFor="endDate">End Date</label>
              <DatePicker
                selected={endDate}
                onChange={(date: any) =>
                  setEndDate(date ? formatDate(date) : null)
                }
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                className="form-control"
                aria-label="End date"
                id="endDate"
                placeholderText="YYYY-MM-DD"
                isClearable
              />
            </div>
          </div>
          {dateError && <div className="text-danger mt-2">{dateError}</div>}
        </div>
      </div>
      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={top_selling_products_by_category_columns_name}
          data={salesProductCategory}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default SalesProductCategory;
