/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  getAbandonedCartSummary,
  getCustomerPurchaseFrequency,
  getNewVsReturningCustomers,
} from "../../Api/Dashboard/mutations";
import Table from "../../Blocks/Table/Table";
import { exportToExcel, formatDate } from "../../Common Functions/Function";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import {
  abandoned_cart_summary_columns_name,
  customer_purchase_frequency_columns_name,
  new_vs_returning_customers_columns_name,
} from "./data";

const CustomerReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [newVsReturningData, setNewVsReturningData] = useState<any>({});
  const [customerPurchaseFrequency, setCustomerPurchaseFrequency] =
    useState<any>({});
  const [abandonedCartSummary, setAbandonedCartSummary] = useState<any>({});
  const [dateError, setDateError] = useState("");
  const [startDate, setStartDate] = useState<Date | null | any>(null);
  const [endDate, setEndDate] = useState<Date | null | any>(null);
  const [isAPiCalled, setIsAPiCalled] = useState<boolean>(false);

  const { storeId } = useStoreId();

  useEffect(() => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const formatDate = (date: Date) =>
      `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;

    setStartDate(formatDate(startOfMonth));
    setEndDate(formatDate(currentDate));
    validateDates(
      formatDate(startOfMonth),
      formatDate(currentDate),
      setDateError,
      fetchNewVsReturningCustomers
    );
  }, []);

  useEffect(() => {
    if (isAPiCalled) {
      validateDates(
        startDate,
        endDate,
        setDateError,
        fetchNewVsReturningCustomers
      );
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  const validateDates = (
    start: string | number | Date,
    end: string | number | Date,
    setDateError: React.Dispatch<React.SetStateAction<string>>,
    fetchNewVsReturningCustomers: (startDate: string, endDate: string) => void
  ) => {
    // If both dates are provided, check their validity
    if (start && end) {
      if (new Date(end) < new Date(start)) {
        setDateError(
          "End date must be greater than or equal to the start date."
        );
        return;
      } else {
        setDateError("");
        fetchNewVsReturningCustomers(start as string, end as string);
      }
    } else {
      setDateError("");
      console.warn(
        "One or both dates missing, calling API to return all data."
      );
      fetchNewVsReturningCustomers(start as string, end as string);
    }
  };

  const fetchNewVsReturningCustomers = async (startDate: any, endDate: any) => {
    try {
      const data = await getNewVsReturningCustomers(startDate, endDate);
      await fetchCustomerPurchaseFrequency(startDate, endDate);
      await fetchAbandonedCartSummary(startDate, endDate);
      setIsAPiCalled(true);
      const newCustomersData =
        data?.new_customers_by_date?.buckets.map(
          (bucket: {
            key_as_string: string | number | Date;
            new_customer_count: { value: any };
          }) => {
            const date = new Date(bucket.key_as_string).toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }
            );
            const newCustomers = bucket.new_customer_count.value;

            return {
              date,
              newCustomers,
            };
          }
        ) || [];

      // Process returning customers data
      const returningCustomersData =
        data?.returning_customers_by_date?.buckets.map(
          (bucket: {
            key_as_string: string | number | Date;
            returning_customer_count: { value: any };
          }) => {
            const date = new Date(bucket.key_as_string).toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }
            );
            const returningCustomers = bucket.returning_customer_count.value;

            return {
              date,
              returningCustomers,
            };
          }
        ) || [];

      // Create a map of all dates with data
      const dateMap: Record<
        string,
        {
          newCustomers: number;
          returningCustomers: number;
          totalCustomers: number;
        }
      > = {};

      newCustomersData.forEach(({ date, newCustomers }: any) => {
        if (!dateMap[date]) {
          dateMap[date] = {
            newCustomers: 0,
            returningCustomers: 0,
            totalCustomers: 0,
          };
        }
        dateMap[date].newCustomers = newCustomers;
        dateMap[date].totalCustomers += newCustomers;
      });

      returningCustomersData.forEach(({ date, returningCustomers }: any) => {
        if (!dateMap[date]) {
          dateMap[date] = {
            newCustomers: 0,
            returningCustomers: 0,
            totalCustomers: 0,
          };
        }
        dateMap[date].returningCustomers = returningCustomers;
        dateMap[date].totalCustomers += returningCustomers;
      });

      // Convert dateMap to an array of updated data
      const updatedData = Object.entries(dateMap).map(([date, values]) => ({
        date,
        ...values,
      }));
      setNewVsReturningData(updatedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setIsLoading(false);
      return [];
    }
  };

  const fetchCustomerPurchaseFrequency = async (
    startDate: any,
    endDate: any
  ) => {
    try {
      const data = await getCustomerPurchaseFrequency(
        storeId,
        startDate,
        endDate
      );
      const updatedData =
        data?.flatMap(
          (bucket: {
            customer_name: { buckets: any[] };
            total_purchases: { value: any };
          }) => {
            const subBucketData = bucket?.customer_name?.buckets?.map(
              (subBucket: { key: any }) => {
                return {
                  customerId: subBucket.key,
                  purchases: bucket.total_purchases.value,
                };
              }
            );
            return subBucketData || []; // Ensure no null/undefined values
          }
        ) || [];
      setIsLoading(false);
      setCustomerPurchaseFrequency(updatedData);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setIsLoading(false);
      return [];
    }
  };

  const fetchAbandonedCartSummary = async (startDate: any, endDate: any) => {
    try {
      const data = await getAbandonedCartSummary(startDate, endDate);
      const updatedData =
        data?.map((bucket: any) => {
          const date = new Date(bucket.key_as_string).toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }
          );
          const totalCarts = bucket?.total_carts?.value;
          const abandonedCarts = bucket?.abandoned_carts?.count?.value;
          const abandonementRate = (abandonedCarts * 100) / totalCarts || 0;

          return { date, totalCarts, abandonedCarts, abandonementRate }; // Ensure no null/undefined values
        }) || [];
      setIsLoading(false);
      setAbandonedCartSummary(updatedData);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setIsLoading(false);
      return [];
    }
  };

  return (
    <div>
      <div className="row my-4 ">
        <div className="col-auto">
          <h3 className="theme_color mb-0 fw-bolder">{"Customer Report"}</h3>
        </div>
        <div className="col-auto ms-auto">
          <button
            className="btn btn-outline-success"
            onClick={() =>
              exportToExcel(
                newVsReturningData,
                [
                  "date",
                  "newCustomers",
                  "returningCustomers",
                  "totalCustomers",
                ],
                {
                  date: "Date",
                  totalOrders: "New Customers",
                  ordersWithErrors: "Returning Customers",
                  errorRate: "Total Customers",
                },
                "new_vs_returning_customers"
              )
            }
            type="button"
          >
            Export
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div className="col-sm-12 col-md-6 col-lg-5 col-xxl-4 mb-4">
          <label className="form-label" htmlFor="dateRange">
            Filter by date range
          </label>
          <div
            id="dateRange"
            className="d-flex flex-column flex-md-row justify-content-between"
          >
            <div className="form-group me-md-2 w-100">
              <label htmlFor="startDate">Start Date</label>
              <DatePicker
                selected={startDate}
                onChange={(date: any) =>
                  setStartDate(date ? formatDate(date) : null)
                }
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                className="form-control"
                aria-label="Start date"
                id="startDate"
                placeholderText="YYYY-MM-DD"
                isClearable
              />
            </div>
            <div className="form-group w-100">
              <label htmlFor="endDate">End Date</label>
              <DatePicker
                selected={endDate}
                onChange={(date: any) =>
                  setEndDate(date ? formatDate(date) : null)
                }
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                className="form-control"
                aria-label="End date"
                id="endDate"
                placeholderText="YYYY-MM-DD"
                isClearable
              />
            </div>
          </div>
          {dateError && <div className="text-danger mt-2">{dateError}</div>}
        </div>
      </div>
      <h5 className="theme_color">{"New vs Returning Customers"}</h5>
      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={new_vs_returning_customers_columns_name}
          data={newVsReturningData}
          isLoading={isLoading}
          Pagination={{
            from: 0,
            size: 50,
            page_no: 1,
            pageSize: 50,
            total_pages: 0,
          }}
        />
      </div>
      <div className="my-8">
        <div className="d-flex p-4">
          <h5 className="theme_color mr-8">{"Customer Purchase Frequency"}</h5>
          <div className="col-auto ms-auto">
            <button
              className="btn btn-outline-success"
              onClick={() =>
                exportToExcel(
                  customerPurchaseFrequency,
                  ["customerId", "purchases"],
                  { customerId: "Customer ID", purchases: "Purchases" },
                  "customer_purchase_frequency"
                )
              }
              type="button"
            >
              Export
            </button>
          </div>
        </div>
        <div className="border p-4 border-grey rounded white_bg">
          <Table
            columns={customer_purchase_frequency_columns_name}
            data={customerPurchaseFrequency}
            isLoading={isLoading}
            Pagination={{
              from: 0,
              size: 50,
              page_no: 1,
              pageSize: 50,
              total_pages: 0,
            }}
          />
        </div>
      </div>
      <div className="my-8">
        <div className="d-flex p-4">
          <h5 className="theme_color mr-8">{"Abandoned Cart Summary"}</h5>
          <div className="col-auto ms-auto">
            <button
              className="btn btn-outline-success"
              onClick={() =>
                exportToExcel(
                  abandonedCartSummary,
                  ["date", "totalCarts", "abandonedCarts", "abandonementRate"],
                  {
                    date: "Date",
                    totalCarts: "Total Carts",
                    abandonedCarts: "Abandoned Carts",
                    abandonementRate: "Abandonment Rate (%)",
                  },
                  "abandoned_cart_summary"
                )
              }
              type="button"
            >
              Export
            </button>
          </div>
        </div>
        <div className="border p-4 border-grey rounded white_bg">
          <Table
            columns={abandoned_cart_summary_columns_name}
            data={abandonedCartSummary}
            isLoading={isLoading}
            Pagination={{
              from: 0,
              size: 50,
              page_no: 1,
              pageSize: 50,
              total_pages: 0,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerReport;
