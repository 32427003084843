/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  getAverageOrderValue,
  getErrorsPerOrder,
  getTotalOrders,
  getTotalSales,
} from "../../Api/Dashboard/mutations";
import Table from "../../Blocks/Table/Table";
import { exportToExcel } from "../../Common Functions/Function";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { gorcolumns_name } from "./data";

const GeneralOverviewReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [totalSales, setTotalSales] = useState<any>({});
  const [totalOrders, setTotalOrders] = useState<any>({});
  const [totalAverageOrder, setTotalAverageOrder] = useState<any>({});
  const [errorsPerOrder, setErrorsPerOrder] = useState<any>({});

  const { storeId } = useStoreId();

  useEffect(() => {
    fetchTotalSales();
    fetchTotalOrders();
    fetchAverageOrderValue();
    fetchErrorsPerOrder();
  }, []);

  const fetchTotalSales = async () => {
    try {
      const data = await getTotalSales(storeId);
      const updatedData = {
        metric: "Total Sales",
        currentMonth: `R ${data?.current_month?.total_sales?.value.toFixed(2)}`,
        currentWeek: `R ${data?.current_week?.total_sales?.value.toFixed(2)}`,
        daily: `R ${data?.daily?.total_sales?.value.toFixed(2)}`,
      };
      setTotalSales(updatedData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchTotalOrders = async () => {
    try {
      const data = await getTotalOrders(storeId);
      const updatedData = {
        metric: "Total Orders",
        currentMonth: data?.current_month?.order_count?.value,
        currentWeek: data?.current_week?.order_count?.value,
        daily: data?.daily?.order_count?.value,
      };
      setTotalOrders(updatedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchAverageOrderValue = async () => {
    const calculateAverage = (total: number, count: number) =>
      count ? `R ${(total / count).toFixed(2)}` : `R ${(0).toFixed(2)}`;
    try {
      const data = await getAverageOrderValue(storeId);
      const updatedData = {
        metric: "Average order value",
        currentMonth: calculateAverage(
          data?.current_month?.total_order_value_sum.value,
          data?.current_month?.order_count?.value
        ),
        currentWeek: calculateAverage(
          data?.current_week?.total_order_value_sum.value,
          data?.current_week?.order_count?.value
        ),
        daily: calculateAverage(
          data?.current_day?.total_order_value_sum.value,
          data?.current_day?.order_count?.value
        ),
      };
      setTotalAverageOrder(updatedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchErrorsPerOrder = async () => {
    try {
      const data = await getErrorsPerOrder(storeId, "", "");
      const errorPercentage = {
        metric: "Errors per order",
        daily: calculateErrorPercentage(
          data.all_orders.current_day.order_value.value,
          data.completed_orders.current_day.order_value.value
        ),
        currentWeek: calculateErrorPercentage(
          data.all_orders.current_week.order_value.value,
          data.completed_orders.current_week.order_value.value
        ),
        currentMonth: calculateErrorPercentage(
          data.all_orders.current_month.order_value.value,
          data.completed_orders.current_month.order_value.value
        ),
      };
      setErrorsPerOrder(errorPercentage);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  function calculateErrorPercentage(
    allOrdersValue: number,
    completedOrdersValue: number
  ) {
    if (allOrdersValue === 0) {
      return "0%"; // No orders, so error percentage is 0%
    }
    const errorPercentage =
      ((allOrdersValue - completedOrdersValue) / allOrdersValue) * 100;
    return `${errorPercentage}%`;
  }

  return (
    <div>
      <div className="row my-4">
        <div className="col-auto">
          <h3 className="theme_color mb-0 fw-bolder">
            {"General Overview Report"}
          </h3>
        </div>
        <div className="col-auto ms-auto">
          <button
            className="btn btn-outline-success"
            onClick={() => {
              exportToExcel(
                [totalSales, totalOrders, totalAverageOrder, errorsPerOrder],
                ["metric", "daily", "currentWeek", "currentMonth"],
                {
                  metric: "Metric",
                  daily: "Daily",
                  currentWeek: "Current Week",
                  currentMonth: "Current Month",
                },
                "general_overview_report"
              );
            }}
            type="button"
          >
            Export
          </button>
        </div>
      </div>

      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={gorcolumns_name}
          data={[totalSales, totalOrders, totalAverageOrder, errorsPerOrder]}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default GeneralOverviewReport;
