/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getAverageOrderTime } from "../../Api/Dashboard/mutations";
import Table from "../../Blocks/Table/Table";
import { exportToExcel, formatDate } from "../../Common Functions/Function";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { average_order_time_columns_name } from "./data";

const AverageOrderTimeReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [averageOrderTime, setAverageOrderTime] = useState<any[]>([]);
  const [dateError, setDateError] = useState("");
  const [startDate, setStartDate] = useState<Date | null | any>(null);
  const [endDate, setEndDate] = useState<Date | null | any>(null);
  const { storeId } = useStoreId();
  const [isAPiCalled, setIsAPiCalled] = useState<boolean>(false);

  useEffect(() => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const formatDate = (date: Date) =>
      `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
    setStartDate(formatDate(startOfMonth));
    setEndDate(formatDate(currentDate));
    validateDates(
      formatDate(startOfMonth),
      formatDate(currentDate),
      setDateError,
      fetchAverageOrderTime
    );
  }, []);

  useEffect(() => {
    if (isAPiCalled) {
      validateDates(startDate, endDate, setDateError, fetchAverageOrderTime);
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  const validateDates = (
    start: string | number | Date,
    end: string | number | Date,
    setDateError: React.Dispatch<React.SetStateAction<string>>,
    fetchAverageOrderTime: (startDate: string, endDate: string) => void
  ) => {
    // If both dates are provided, check their validity
    if (start && end) {
      if (new Date(end) < new Date(start)) {
        setDateError(
          "End date must be greater than or equal to the start date."
        );
        return;
      } else {
        setDateError("");
        fetchAverageOrderTime(start as string, end as string);
      }
    } else {
      setDateError("");
      console.warn(
        "One or both dates missing, calling API to return all data."
      );
      fetchAverageOrderTime(start as string, end as string);
    }
  };

  const fetchAverageOrderTime = async (startDate: any, endDate: any) => {
    try {
      const data = await getAverageOrderTime(storeId, startDate, endDate);

      const formatTimeAMPM = (date: Date) => {
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const period = hours >= 12 ? "PM" : "AM";

        const formattedHours = hours % 12 || 12; // Convert 0 hour to 12
        return `${String(formattedHours).padStart(2, "0")}:${String(minutes).padStart(2, "0")} ${period}`;
      };
      setIsAPiCalled(true);
      const ordersWithTimeDiff = data.map(
        (order: {
          order_placed_time: { value: any; value_as_string: any };
          order_closed_time: { value: any; value_as_string: any };
          key: any;
        }) => {
          const placedTime = order.order_placed_time.value; // in milliseconds
          const closedTime = order.order_closed_time.value; // in milliseconds

          const timeDifference = closedTime - placedTime; // Time difference in milliseconds
          const totalMinutes = Math.floor(timeDifference / 60000); // Total minutes
          const hours = Math.floor(totalMinutes / 60); // Extract hours
          const minutes = totalMinutes % 60; // Extract remaining minutes

          // Format time difference as HH:MM
          const timeDifferenceFormatted = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;

          // Format order placed and closed times
          const placedDate = new Date(placedTime);
          const closedDate = new Date(closedTime);

          const orderPlacedFormatted = formatTimeAMPM(placedDate);
          const orderClosedFormatted = formatTimeAMPM(closedDate);
          return {
            orderId: order.key,
            orderPlacedTime: orderPlacedFormatted,
            orderClosedTime: orderClosedFormatted,
            timeTaken: timeDifferenceFormatted,
          };
        }
      );
      setAverageOrderTime(ordersWithTimeDiff);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="row my-4 ">
        <div className="col-auto">
          <h3 className="theme_color mb-0 fw-bolder">
            {"Average Order Time Report"}
          </h3>
        </div>
        <div className="col-auto ms-auto">
          <button
            className="btn btn-outline-success"
            onClick={() => {
              exportToExcel(
                averageOrderTime,
                ["orderId", "orderPlacedTime", "orderClosedTime", "timeTaken"],
                {
                  orderId: "Order ID",
                  orderPlacedTime: "Order Placed Time",
                  orderClosedTime: "Order Closed Time",
                  timeTaken: "Time Taken (HH:MM)",
                },
                "average_order_time_report"
              );
            }}
            type="button"
          >
            Export
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div className="col-sm-12 col-md-6 col-lg-5 col-xxl-4 mb-4 ">
          <label className="form-label" htmlFor="dateRange">
            Filter by date range
          </label>
          <div
            id="dateRange"
            className="d-flex flex-column flex-md-row justify-content-between"
          >
            <div className="form-group me-md-2 w-100">
              <label htmlFor="startDate">Start Date</label>
              <DatePicker
                selected={startDate}
                onChange={(date: any) =>
                  setStartDate(date ? formatDate(date) : null)
                }
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                className="form-control"
                aria-label="Start date"
                id="startDate"
                placeholderText="YYYY-MM-DD"
                isClearable
              />
            </div>
            <div className="form-group w-100">
              <label htmlFor="endDate">End Date</label>
              <DatePicker
                selected={endDate}
                onChange={(date: any) =>
                  setEndDate(date ? formatDate(date) : null)
                }
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                className="form-control"
                aria-label="End date"
                id="endDate"
                placeholderText="YYYY-MM-DD"
                isClearable
              />
            </div>
          </div>
          {dateError && <div className="text-danger mt-2">{dateError}</div>}
        </div>
      </div>
      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={average_order_time_columns_name}
          data={averageOrderTime}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default AverageOrderTimeReport;
