/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getTopSellingProductByCategory } from "../../Api/Dashboard/mutations";
import Table from "../../Blocks/Table/Table";
import { exportToExcel } from "../../Common Functions/Function";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { sales_product_category_columns_name } from "./data";

const TopSellingProductsCategory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [topSellingProductByCategory, setTopSellingProductByCategory] =
    useState<any[]>([]);
  const { storeId } = useStoreId();

  useEffect(() => {
    fetchTopSellingProductByCategory();
  }, []);

  const fetchTopSellingProductByCategory = async () => {
    try {
      const data = await getTopSellingProductByCategory(storeId);
      const productSales = data.map(
        (item: {
          category: { categoryName: any };
          key: any;
          total_sales: { value: number };
          total_units_sold: { value: any };
        }) => ({
          productCategory: item.category.categoryName,
          productName: item.key,
          totalSales: item.total_sales.value.toFixed(2), // Keep 2 decimal places for sales
          totalUnitsSold: item.total_units_sold.value, // Total units sold
        })
      );
      setTopSellingProductByCategory(productSales);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="row my-4 ">
        <div className="col-auto">
          <h3 className="theme_color mb-0 fw-bolder">
            {"Top Selling Products by Category"}
          </h3>
        </div>
        <div className="col-auto ms-auto">
          <button
            className="btn btn-outline-success"
            onClick={() => {
              exportToExcel(
                topSellingProductByCategory,
                [
                  "productCategory",
                  "productName",
                  "totalSales",
                  "totalUnitsSold",
                ],
                {
                  productCategory: "Product Category",
                  productName: "Product Name",
                  totalSales: "Total Sales (R)",
                  totalUnitsSold: "Total Units Sold",
                },
                "top_selling_products_by_category_report"
              );
            }}
            type="button"
          >
            Export
          </button>
        </div>
      </div>
      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={sales_product_category_columns_name}
          data={topSellingProductByCategory}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default TopSellingProductsCategory;
