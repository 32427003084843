import debounce from "lodash.debounce";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  getAllExportReports,
  serchReports,
} from "../../Api/Search/Reports/SalesReports";
import Table from "../../Blocks/Table/Table";
import {
  exportToExcel,
  handlePageSizes,
  handlePaginations,
  updateFilterAndPagination,
} from "../../Common Functions/Function";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { columns_name } from "./data";
import { reportResponse } from "./utils";

type SearchData = {
  fields: string;
  value: string;
};

const SalesReports = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [storelist, setStore] = useState<any[]>([]);
  const [filtervalue, setFilterValue] = useState<SearchData[]>([]);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const { storeId } = useStoreId();
  const [exportData, setExportData] = useState<any[]>([]);
  const [exportButtonEnable, setexportButtonEnable] = useState(true);
  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50,
    total_pages: 0,
  });

  // eslint-disable-next-line
  const getSaleslist = useCallback(
    debounce(async (filtervalue: SearchData[], Pagination: any) => {
      setIsLoading(true);
      try {
        const res = await serchReports(
          "productstock",
          filtervalue,
          Pagination,
          storeId
        );
        const result = await reportResponse(res);
        setStore(result?.modifiedData || []);
        setTotal(result?.total);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }, 3000),
    [storeId]
  );

  useEffect(() => {
    getSaleslist(filtervalue, Pagination);
  }, [getSaleslist, filtervalue, Pagination]);

  // Handle filter change
  const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { name, value } = e.target;
      updateFilterAndPagination(
        filtervalue,
        setFilterValue,
        setPagination,
        name,
        value
      );
    }
  };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  useEffect(() => {
    const getReports = async () => {
      await getAllExportReports("", "productstock", storeId)
        .then(async (res) => {
          const result = await reportResponse(res);
          setExportData(result?.modifiedData || []);
          setexportButtonEnable(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getReports();
  }, [storeId]);

  return (
    <div>
      <div className="row my-4">
        <div className="col-auto">
          <h3 className="theme_color mb-0 fw-bolder">{"Sales Reports"}</h3>
        </div>
        {!exportButtonEnable && (
          <div className="col-auto ms-auto">
            <button
              className="btn btn-outline-success"
              onClick={() => {
                exportToExcel(
                  storelist,
                  [
                    "description",
                    "sku",
                    "availableQuantity",
                    "sellQuantity",
                    "productType",
                  ],
                  {
                    description: "Name",
                    sku: "SKU",
                    availableQuantity: "Available Quantity",
                    sellQuantity: "Sell Quantity",
                    productType: "Product Type",
                  },
                  "sales_report"
                );
              }}
              type="button"
            >
              Export
            </button>
          </div>
        )}
      </div>

      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={columns_name}
          data={storelist}
          paginationShow={true}
          isLoading={isLoading}
          handlePagination={handlePagination}
          Pagination={Pagination}
          handlePageSize={handlePageSize}
          total={total}
          filterdata={filterdata}
        />
      </div>
    </div>
  );
};

export default SalesReports;
