import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Data, tabledata } from "./data";
import moment from "moment";
import { Link } from "react-router-dom";
import no_image from "../../Asset/no_image.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { isValidJSON } from "../../Common Functions/Function";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "../../Atoms/Select";
import { getDriver } from "../../Api/Search/Driver/Driver";
import { useStoreId } from "../../Component/ContextAPI/StoreIdContext";
import { updateOrder } from "../../Api/Order/mutation";
import { useAlert } from "../../Component/ContextAPI/ContextApi";
interface TableProps {
  columns: any[];
  data: any[];
  paginationShow?: boolean;
  url?: string;
  handleDelete?: (data: any) => void;
  handleEdit?: (data: any) => void;
  handleInfo?: (data: any) => void;
  handlePriorityData?: (data: any) => void;
  isLoading?: boolean;
  total?: number;
  filterdata?: (data: any) => void;
  handlePagination?: (data: any) => void;
  Pagination?: {
    from: number;
    size: number;
    page_no: number;
    pageSize: number;
    total_pages: number;
  };
  handlePageSize?: (data: any) => void;
  draggable?: boolean;
}

const Table: React.FC<TableProps> = ({
  columns,
  data,
  paginationShow,
  url,
  handleDelete,
  handleEdit,
  total,
  filterdata,
  isLoading,
  handlePagination,
  Pagination,
  handlePageSize,
  handleInfo,
  draggable,
  handlePriorityData,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filteredData, setFilteredData] = useState<tabledata[]>(data);
  const [driverAssignIsLoading, setDriverAssignIsLoading] = useState(false);
  const { storeId } = useStoreId();
  const fromValueRef = useRef<HTMLInputElement>(null);
  const toValueRef = useRef<HTMLInputElement>(null);
  const [drivers, setDrivers] = useState<any>([]);
  const { setAlert, setErrorType, setMessage } = useAlert();

  // drop down selection
  const [loadingRowId, setLoadingRowId] = useState<string | null>(null);

  const DriverAssign = async (data: any, item: any) => {
    let val = data;
    setLoadingRowId(item.id);
    const actionLog = [
      ...(item?.actionLog || []),
      {
        timestamp: new Date().toISOString(),
        action: val === null ? "Driver un-assign" : "Driver assign",
      },
    ];
    let driverDetails = {
      expireTime: new Date().toISOString(),
      comments: JSON.stringify(item?.driverDetails?.comments),
      driverId: val?.id,
      status: item?.driverDetails?.status,
    };

    let orderInput = {
      driverId: val === null ? "" : val.id,
      driverDetails: driverDetails,
      PK: item?.PK,
      SK: item?.SK,
      entityName: item?.entityName,
      id: item?.id,
      addressList: JSON.stringify(item?.addressList),
      chat: JSON.stringify(item?.chat),
      orderDetails: JSON.stringify(item?.orderDetails),
      paymentDetails: JSON.stringify(item?.paymentDetails),
      specialinStructions: JSON.stringify(item?.specialinStructions),
      updatedDate: new Date().toISOString(),
      actionLog: JSON.stringify(actionLog),
      refundOrderDetails: JSON.stringify(item?.refundOrderDetails),
      isDeleted: item?.isDeleted,
      storeId: item?.storeId,
      itemAction: "changeDriver",
    };
    setDriverAssignIsLoading(true);
    updateOrder(orderInput, item?.PK, item?.SK)
      .then((res: any) => {
        console.log("res", res);
        const result = isValidJSON(res?.data?.updateOrder)
          ? JSON.parse(res?.data?.updateOrder)
          : res?.data?.updateOrder;

        if (result?.statusCode === 200) {
          filteredData.forEach((items) => {
            if (items.id === item.id) {
              items.driverId = val ? val.id : "";
            }
          });
          setDriverAssignIsLoading(false);
          setAlert(true);
          setMessage(
            val === null
              ? "Driver has been unassigned successfully."
              : "Driver has been assigned successfully."
          );
          setErrorType(val === null ? "warning" : "success");
        } else {
          setDriverAssignIsLoading(false);

          setAlert(true);
          setMessage(res?.errors?.[0]?.message || "An error occurred");
          setErrorType("error");
        }
      })
      .catch((err: any) => {
        setDriverAssignIsLoading(false);
        console.error("Error updating order:", err);
        setAlert(true);
        setMessage("Failed to assign driver. Please try again.");
        setErrorType("error");
      });
  };

  const getAllDrivers = () => {
    getDriver("", "user", storeId)
      .then((res) => {
        const modifiedData = res?.hits?.map((val: any) => {
          return {
            id: val._source.userId,
            ...val._source,
            value: val._source.userId,
            label: val._source.fullName,
          };
        });
        setDrivers(modifiedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllDrivers();
    // eslint-disable-next-line
  }, [storeId]);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedRows(
        // @ts-expect-error
        filteredData.map((record: { id: number }) => record.id)
      );
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowCheckboxChange = (id: number) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleDeleteClick = (data: tabledata) => {
    if (handleDelete && data) {
      handleDelete(data);
    }
  };

  const handleEditClick = (data: any) => {
    if (handleEdit && data) {
      handleEdit(data);
    }
  };

  const handleInfoClick = (data: any) => {
    if (handleInfo && data) {
      handleInfo(data);
    }
  };

  const handlePaginationClick = (trigger: string) => {
    handlePagination && handlePagination(trigger);
  };

  const handleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (e && e.target) {
      filterdata && filterdata(e);
    }
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handlepage = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e && e.target) {
      handlePageSize && handlePageSize(e);
    }
  };

  const [draggedRowIndex, setDraggedRowIndex] = useState<number | null>(null);

  const handlePriorityDataChanges = (data: boolean) => {
    if (typeof handlePriorityData === "function") {
      handlePriorityData(data);
    }
  };

  const handleDragStart = (index: number) => {
    setDraggedRowIndex(index);
  };

  const handleDragOver = (event: React.DragEvent<HTMLTableRowElement>) => {
    event.preventDefault(); // Allow the drop
  };

  const handleDrop = async (index: number) => {
    if (draggedRowIndex !== null) {
      const newDraggedRowStatus = false;

      const updatedRows = [...filteredData];
      const draggedRow = updatedRows.splice(draggedRowIndex, 1)[0];

      // Set priority key for the dragged row
      draggedRow.priority = index; // Set the current index as priority

      // Insert the dragged row at the new index
      updatedRows.splice(index, 0, draggedRow);

      // Update priority for all rows based on their new index
      updatedRows.forEach((row, i) => {
        row.priority = i; // Update priority based on the new order
      });

      console.log("updatedRows with priority:", updatedRows);

      setFilteredData(updatedRows); // Update state with the new row order
      handlePriorityDataChanges(newDraggedRowStatus);
    }

    setDraggedRowIndex(null); // Reset dragged index after drop
  };

  return (
    <div className="tableWrap">
      <ToastContainer autoClose={3000} />
      <div className="table-container">
        <table className="table table-fixed">
          <thead>
            <tr>
              {columns?.map((item: Data, key: number) => (
                <th key={key}>
                  <div>
                    <div>{item?.label}</div>
                    <div>
                      {item?.type === "checkbox" && (
                        <input
                          type="checkbox"
                          className="form-check-input cursor-pointer mb-0"
                          checked={selectAll}
                          onChange={toggleSelectAll}
                        />
                      )}
                      {item?.sub_header?.inputtype === "input" && (
                        <input
                          type={item?.type}
                          className="form-control"
                          style={{ width: item?.sub_header?.size }}
                          placeholder={item?.sub_header?.placeholder}
                          name={item?.field}
                          onChange={(e) => handleSearch(e)}
                        />
                      )}

                      {item?.type === "icon" &&
                        item?.sub_header?.inputtype === "select" && (
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            style={{ width: item?.sub_header?.size }}
                            name={item?.field}
                            onChange={(e) => handleSearch(e)}
                          >
                            <option selected value={""}>
                              All
                            </option>
                            <option value={"true"}>Active</option>
                            <option value={"false"}>In - active</option>
                          </select>
                        )}
                      {item?.type === "yes" &&
                        item?.sub_header?.inputtype === "select" && (
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            style={{ width: item?.sub_header?.size }}
                            name={item?.field}
                            onChange={(e) => handleSearch(e)}
                          >
                            <option selected value={""}>
                              All
                            </option>
                            <option value={"true"}>Yes</option>
                            <option value={"false"}>No</option>
                          </select>
                        )}
                      {item?.sub_header?.inputtype === "fromTo" && (
                        <div className="d-flex gap-2">
                          <input
                            type={item?.type}
                            key={key}
                            id={`from${key}`}
                            className="form-control"
                            style={{ width: item?.sub_header?.from?.size }}
                            placeholder={item?.sub_header?.from?.placeholder}
                            name={`from${item?.field}`}
                            onChange={(e) => handleSearch(e)}
                            ref={fromValueRef}
                          />
                          <input
                            type={item?.type}
                            key={key}
                            id={`to${key}`}
                            className="form-control"
                            style={{ width: item?.sub_header?.to?.size }}
                            placeholder={item?.sub_header?.to?.placeholder}
                            name={`to${item?.field}`}
                            onChange={(e) => handleSearch(e)}
                            ref={toValueRef}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {isLoading ? (
              [...Array(Pagination?.pageSize)].map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map((val, cellIndex) => (
                    <td key={cellIndex}>
                      <div className="shimmer-cell"></div>
                    </td>
                  ))}
                </tr>
              ))
            ) : filteredData?.length > 0 ? (
              filteredData?.map((item: tabledata, key: number) => (
                <tr
                  key={key}
                  draggable={draggable}
                  onDragStart={
                    draggable ? () => handleDragStart(key) : undefined
                  }
                  onDragOver={draggable ? handleDragOver : undefined}
                  onDrop={draggable ? () => handleDrop(key) : undefined}
                >
                  {columns?.map(
                    (col: { type: string; field: string }, index: number) => {
                      switch (col.type) {
                        case "checkbox":
                          return (
                            <td key={index}>
                              <input
                                type="checkbox"
                                className="form-check-input cursor-pointer"
                                checked={selectedRows.includes(
                                  item.id as number
                                )}
                                onChange={() =>
                                  handleRowCheckboxChange(item?.id as number)
                                }
                              />
                            </td>
                          );
                        case "text":
                          return (
                            <td key={index}>
                              <span>
                                {url ? (
                                  <Link
                                    className="text-decoration-none theme_color"
                                    to={url}
                                  >
                                    {item[col.field]}
                                  </Link>
                                ) : (
                                  item[col.field]
                                )}
                              </span>
                            </td>
                          );
                        case "date":
                          return (
                            <td key={index}>
                              <span>
                                {
                                  //@ts-expect-error
                                  moment(item[col.field]).format("DD MMM, YYYY")
                                }
                              </span>
                            </td>
                          );
                        case "icon":
                          return (
                            <td key={index}>
                              {isValidJSON(item[col.field]) === true ||
                              item[col.field] === "true" ? (
                                <i className="bi bi-circle-fill status_icon"></i>
                              ) : (
                                <i className="bi bi-circle-fill status_icon_deactive"></i>
                              )}
                            </td>
                          );
                        case "yes":
                          return (
                            <td key={index}>
                              {isValidJSON(item[col.field]) === true ||
                              item[col.field] === "true"
                                ? "Yes"
                                : "No"}
                            </td>
                          );
                        case "img":
                          return (
                            <td key={index}>
                              <img
                                //@ts-expect-error
                                src={item[col.field] || no_image}
                                height={45}
                                width={58.66}
                                alt="img"
                              />
                            </td>
                          );
                        case "multipleImg":
                          // Ensure item[col.field] is an array
                          const images = Array.isArray(item[col.field])
                            ? (item[col.field] as unknown as {
                                url: string;
                                type: string;
                              }[])
                            : [];

                          return (
                            <td key={index}>
                              {images.length > 0 ? (
                                <Carousel
                                  showThumbs={false}
                                  showArrows={false}
                                  showStatus={false}
                                  showIndicators={false}
                                  autoPlay
                                  infiniteLoop
                                  transitionTime={1000}
                                  width={58.66}
                                  useKeyboardArrows
                                >
                                  {images.map((imageObj, idx) => {
                                    const url = imageObj.url;
                                    const type = imageObj.type;
                                    if (type === "image") {
                                      return (
                                        <div key={idx}>
                                          <img
                                            src={url}
                                            height={45}
                                            width={58.66}
                                            alt={url}
                                          />
                                        </div>
                                      );
                                    } else if (type === "video") {
                                      return (
                                        <div key={idx}>
                                          <video
                                            height={45}
                                            width={58.66}
                                            controls
                                            autoPlay
                                          >
                                            <source
                                              src={url}
                                              height={45}
                                              width={58.66}
                                              type="video/mp4"
                                            />
                                          </video>
                                        </div>
                                      );
                                    }
                                    return <></>;
                                  })}
                                </Carousel>
                              ) : (
                                <img
                                  src={no_image}
                                  height={45}
                                  width={58.66}
                                  alt="no_image"
                                />
                              )}
                            </td>
                          );

                        case "select":
                          return (
                            <td key={index}>
                              {item?.driverStatus !== "Completed" &&
                              item?.status !== "Completed" &&
                              item?.status !== "failed" && //@ts-expect-error
                              !item?.actionLog?.some(
                                (log: any) =>
                                  log?.action === "Order Forcefully Closed"
                              ) ? (
                                <Select
                                  cacheOptions={true}
                                  onChange={(e) => DriverAssign(e, item)}
                                  defaultOptions={drivers}
                                  data={drivers}
                                  value={
                                    drivers.find(
                                      (items: { id: string }) =>
                                        items?.id === item[col.field]
                                    ) ||
                                    drivers.find(
                                      (items: { SK: string }) =>
                                        items?.SK === item[col.field]
                                    ) ||
                                    null
                                  }
                                  placeholder="Please select a driver"
                                  isLoading={
                                    driverAssignIsLoading &&
                                    loadingRowId === item.id // Only show loader for the specific row
                                  }
                                />
                              ) : (
                                <>
                                  {drivers.find(
                                    (driver: { id: string }) =>
                                      driver?.id === item?.driverId
                                  )?.fullName ?? "N/A"}
                                </>
                              )}
                            </td>
                          );

                        case "Action":
                          return (
                            <td key={index} width={"80px"}>
                              <div className="d-flex align-items-center justify-content-between">
                                <span
                                  className="me-4 cursor-pointer tooltip-container"
                                  onClick={() => {
                                    item[col.field] === "false" ||
                                    item[col.field] === false ||
                                    (typeof item[col.field] === "string" && //@ts-expect-error
                                      item[col.field].includes("-") && //@ts-expect-error
                                      item[col.field].split("-")[1] === "false")
                                      ? handleEditClick(item)
                                      : toast.info(
                                          "Please enable it first. It is currently disabled.."
                                        );
                                  }}
                                >
                                  <i className="bi bi-pencil-square"></i>

                                  {item[col.field] === "false" ||
                                  item[col.field] === false ||
                                  (typeof item[col.field] === "string" && //@ts-expect-error
                                    item[col.field].includes("-") && //@ts-expect-error
                                    item[col.field].split("-")[1] ===
                                      "false") ? (
                                    <span className="tooltip-text">Edit</span>
                                  ) : (
                                    <span className="tooltip-text">
                                      Disable
                                    </span>
                                  )}
                                </span>
                                <div className="form-check form-switch tooltip-container">
                                  <input
                                    className="form-check-input cursor-pointer"
                                    type="checkbox"
                                    role="switch"
                                    id={`itemStatus-${index}`}
                                    name="itemStatus"
                                    onChange={() => handleDeleteClick(item)}
                                    checked={
                                      item[col.field] === "false" ||
                                      item[col.field] === false ||
                                      (typeof item[col.field] === "string" && //@ts-expect-error
                                        item[col.field].includes("-") && //@ts-expect-error
                                        item[col.field].split("-")[1] ===
                                          "false")
                                    }
                                    value={
                                      item[col.field] === "false" ||
                                      item[col.field] === false ||
                                      (typeof item[col.field] === "string" && //@ts-expect-error
                                        item[col.field].includes("-") && //@ts-expect-error
                                        item[col.field].split("-")[1] ===
                                          "true")
                                        ? "false"
                                        : "true"
                                    }
                                  />
                                  <span className="tooltip-text">
                                    Toggle to{" "}
                                    {item[col.field] === "false" ||
                                    item[col.field] === false ||
                                    (typeof item[col.field] === "string" && //@ts-expect-error
                                      item[col.field].includes("-") && //@ts-expect-error
                                      item[col.field].split("-")[1] === "false")
                                      ? "deactivate"
                                      : "activate"}
                                  </span>
                                </div>
                              </div>
                            </td>
                          );
                        case "Details":
                          return (
                            <td key={index} width={"80px"}>
                              <span
                                className="me-4 cursor-pointer tooltip-container"
                                onClick={() => handleInfoClick(item)}
                              >
                                <i className="bi bi-info-circle"></i>
                                <span className="tooltip-text">Info</span>
                              </span>
                            </td>
                          );
                        default:
                          return null;
                      }
                    }
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={Math.round(columns.length / 2) + 2}>
                  <div
                    style={{ width: "100%", height: "50vh" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div className="fs-4">{"no data found"}</div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-between mt-4">
          <ul className="pagination gap-2 left-side">
            <li className="page-item theme_color align-self-center pr-4">
              <div className="shimmer-cell" style={{ width: "50px" }}></div>
            </li>
            <li className="page-item theme_color align-self-center pr-4">
              <div className="shimmer-cell" style={{ width: "50px" }}></div>
            </li>
            <li className="page-item theme_color align-self-center">
              <div className="shimmer-cell"></div>
            </li>
          </ul>

          <ul className="pagination justify-content-center gap-2 align-items-center">
            <li
              className={`page-item round_circle_green  pointer shimmer-cell`}
            ></li>
            <li
              className={`page-item round_circle_white  pointer shimmer-cell`}
            ></li>
            <li className="page-item shimmer-cell"></li>
          </ul>
        </div>
      ) : (
        paginationShow && (
          <div className="d-flex justify-content-between mt-4">
            <ul className="pagination gap-2 left-side">
              <li className="page-item theme_color align-self-center pr-4">
                <span>{"Show"}</span>
              </li>
              <input
                className="form-control  theme_color"
                style={{ width: "50px" }}
                min={1}
                max={data.length}
                height={"36px"}
                onChange={handlepage}
                value={Pagination?.pageSize}
              />
              <li className="page-item theme_color align-self-center">
                <span>{"Per Page"}</span>
              </li>
            </ul>

            <ul className="pagination justify-content-center gap-2 align-items-center">
              <li
                className={`page-item ${
                  Pagination?.from === 1 ? "disabled" : ""
                } round_circle_white  pointer `}
                onClick={() =>
                  Pagination &&
                  Pagination?.page_no > 1 &&
                  handlePaginationClick("prev")
                }
              >
                <i className="bi bi-caret-left"></i>
              </li>
              <li
                className={`page-item    
                 round_circle_green`}
              >
                {Pagination?.page_no}
              </li>

              {Pagination &&
                total &&
                Pagination?.page_no <
                  Math.ceil(total / Pagination?.pageSize) && (
                  <li
                    className={`page-item round_circle_white  pointer`}
                    onClick={() => handlePaginationClick("next")}
                  >
                    <i className="bi bi-caret-right"></i>{" "}
                  </li>
                )}
              <li className="page-item">
                {total ? total : filteredData.length} records
              </li>
            </ul>
          </div>
        )
      )}
    </div>
  );
};

export default Table;
