/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getOutOfStockProducts } from "../../Api/Dashboard/mutations";
import Table from "../../Blocks/Table/Table";
import { exportToExcel } from "../../Common Functions/Function";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { out_of_stock_products_columns_name } from "./data";

const OutOfStock = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [outOfStockProducts, setOutOfStockProducts] = useState<any>({});

  const { storeId } = useStoreId();

  useEffect(() => {
    fetchOutOfStockProducts();
  }, []);

  const fetchOutOfStockProducts = async () => {
    try {
      const data = await getOutOfStockProducts(storeId);
      const updateData = data.map((item: any) => ({
        productName: item._source.description,
        sellQuantity: item._source.sellQuantity, // Keep 2 decimal places for sales
        availableQuantity: item._source.availableQuantity, // Total units sold
      }));
      setOutOfStockProducts(updateData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="row my-4 ">
        <div className="col-auto">
          <h3 className="theme_color mb-0 fw-bolder">{"Out Of Stock"}</h3>
        </div>
        <div className="col-auto ms-auto">
          <button
            className="btn btn-outline-success"
            onClick={() => {
              exportToExcel(
                outOfStockProducts,
                ["productName", "sellQuantity", "availableQuantity"],
                {
                  productName: "Product Name",
                  sellQuantity: "Sell Quantity",
                  availableQuantity: "Available Quantity",
                },
                "out_of_stock_products"
              );
            }}
            type="button"
          >
            Export
          </button>
        </div>
      </div>

      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={out_of_stock_products_columns_name}
          data={outOfStockProducts}
          isLoading={isLoading}
          Pagination={{
            from: 0,
            size: 50,
            page_no: 1,
            pageSize: 50,
            total_pages: 0,
          }}
        />
      </div>
    </div>
  );
};

export default OutOfStock;
