/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getTotalSalesOrderReports } from "../../Api/Dashboard/mutations";
import Table from "../../Blocks/Table/Table";
import { exportToExcel, formatDate } from "../../Common Functions/Function";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { table_sales_report_columns_name } from "./data";

const TotalSalesOrdersReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [totalSalesOrderReport, setTotalSalesOrderReport] = useState<any>({});
  const [dateError, setDateError] = useState("");
  const [startDate, setStartDate] = useState<Date | null | any>(null);
  const [endDate, setEndDate] = useState<Date | null | any>(null);
  const [isAPiCalled, setIsAPiCalled] = useState<boolean>(false);

  const { storeId } = useStoreId();

  useEffect(() => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const formatDate = (date: Date) =>
      `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;

    setStartDate(formatDate(startOfMonth));
    setEndDate(formatDate(currentDate));
    validateDates(
      formatDate(startOfMonth),
      formatDate(currentDate),
      setDateError,
      fetchTotalSalesOrderReports
    );
  }, []);

  useEffect(() => {
    if (isAPiCalled) {
      validateDates(
        startDate,
        endDate,
        setDateError,
        fetchTotalSalesOrderReports
      );
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  const validateDates = (
    start: string | number | Date,
    end: string | number | Date,
    setDateError: React.Dispatch<React.SetStateAction<string>>,
    fetchTotalSalesOrderReports: (startDate: string, endDate: string) => void
  ) => {
    // If both dates are provided, check their validity
    if (start && end) {
      if (new Date(end) < new Date(start)) {
        setDateError(
          "End date must be greater than or equal to the start date."
        );
        return;
      } else {
        setDateError("");
        fetchTotalSalesOrderReports(start as string, end as string);
      }
    } else {
      setDateError("");
      console.warn(
        "One or both dates missing, calling API to return all data."
      );
      fetchTotalSalesOrderReports(start as string, end as string);
    }
  };

  const fetchTotalSalesOrderReports = async (startDate: any, endDate: any) => {
    try {
      const data = await getTotalSalesOrderReports(storeId, startDate, endDate);
      setIsAPiCalled(true);
      const categories =
        data.length > 0
          ? data.map((bucket: { key_as_string: any }) => bucket.key_as_string)
          : [];
      const totalOrders =
        data.length > 0
          ? data.map(
              (bucket: { total_orders: { value: any } }) =>
                bucket.total_orders.value
            )
          : [];
      const totalSales =
        data.length > 0
          ? data.map((bucket: { total_sales: { value: any } }) =>
              bucket.total_sales.value.toFixed(2)
            )
          : [];
      const averageOrderValue =
        data.length > 0
          ? data.map(
              (bucket: {
                total_orders: { value: number };
                total_sales: { value: number };
              }) => {
                if (bucket.total_orders.value > 0) {
                  return (
                    bucket.total_sales.value / bucket.total_orders.value
                  ).toFixed(2);
                } else {
                  return "0.00"; // Avoid division by zero
                }
              }
            )
          : [];

      // Combine data into a single array of objects
      const transformedData = categories.map((date: string, index: number) => ({
        date,
        totalSales: totalSales[index],
        totalOrders: totalOrders[index],
        averageOrderValue: averageOrderValue[index],
      }));
      setTotalSalesOrderReport(transformedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="row my-4 ">
        <div className="col-auto">
          <h3 className="theme_color mb-0 fw-bolder">
            {"Total Sales and Orders Report"}
          </h3>
        </div>
        <div className="col-auto ms-auto">
          <button
            className="btn btn-outline-success"
            onClick={() => {
              exportToExcel(
                totalSalesOrderReport,
                ["date", "totalSales", "totalOrders", "averageOrderValue"],
                {
                  date: "Date",
                  totalSales: "Total Sales (R)",
                  totalOrders: "Total Orders",
                  averageOrderValue: "Average Order Value",
                },
                "total_sales_order_report"
              );
            }}
            type="button"
          >
            Export
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div className="col-sm-12 col-md-6 col-lg-5 col-xxl-4 mb-4">
          <label className="form-label" htmlFor="dateRange">
            Filter by date range
          </label>
          <div
            id="dateRange"
            className="d-flex flex-column flex-md-row justify-content-between"
          >
            <div className="form-group me-md-2 w-100">
              <label htmlFor="startDate">Start Date</label>

              <DatePicker
                selected={startDate}
                onChange={(date: any) =>
                  setStartDate(date ? formatDate(date) : null)
                }
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                className="form-control"
                aria-label="Start date"
                id="startDate"
                placeholderText="YYYY-MM-DD"
                isClearable
              />
            </div>
            <div className="form-group w-100">
              <label htmlFor="endDate">End Date</label>

              <DatePicker
                selected={endDate}
                onChange={(date: any) =>
                  setEndDate(date ? formatDate(date) : null)
                }
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                className="form-control"
                aria-label="End date"
                id="endDate"
                placeholderText="YYYY-MM-DD"
                isClearable
              />
            </div>
          </div>
          {dateError && <div className="text-danger mt-2">{dateError}</div>}
        </div>
      </div>
      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={table_sales_report_columns_name}
          data={totalSalesOrderReport}
          isLoading={isLoading}
          Pagination={{
            from: 0,
            size: 50,
            page_no: 1,
            pageSize: 50,
            total_pages: 0,
          }}
        />
      </div>
    </div>
  );
};

export default TotalSalesOrdersReport;
