import { getImage } from "../Api/Product/mutations";
import imageCompression from "browser-image-compression";
import * as XLSX from "xlsx";
export const generateUUID = () => {
  const date = new Date().toISOString().replace(/[-:.TZ]/g, "");
  const uuid = `${date}`.replace(/[x]/g, () => {
    return ((Math.random() * 16) | 0).toString(16);
  });
  return uuid;
};

interface FilterValue {
  fields: string;
  value: any;
}

interface Pagination {
  from: number;
  size: number;
  page_no: number;
  pageSize: number;
  total_pages: number;
}

export const updateFilterAndPagination = (
  filtervalue: FilterValue[],
  setFilterValue: (value: FilterValue[]) => void,
  setPagination: (value: (prev: Pagination) => Pagination) => void,
  name: string,
  value: any
) => {
  const isFromField = name.startsWith("from");
  const isToField = name.startsWith("to");
  const baseFieldName = isFromField
    ? name.substring(4)
    : isToField
      ? name.substring(2)
      : name;
  const existingIndex = filtervalue.findIndex(
    (obj) => obj.fields === baseFieldName
  );
  if (existingIndex !== -1) {
    const updatedFilterValue = [...filtervalue];
    const existingValues = updatedFilterValue[existingIndex].value || {};
    if (isFromField || isToField) {
      updatedFilterValue[existingIndex].value = {
        ...existingValues,
        [isFromField ? "from" : "to"]: value,
      };
    } else {
      updatedFilterValue[existingIndex].value = value;
    }
    setPagination((prev) => ({
      ...prev,
      from: 0,
      size: prev?.pageSize,
      pageSize: prev?.pageSize,
      page_no: 1,
    }));
    setFilterValue(updatedFilterValue);
  } else {
    const newObject: any = {
      fields: baseFieldName,
    };
    if (isFromField || isToField) {
      newObject.value = {
        [isFromField ? "from" : "to"]: value,
      };
    } else {
      newObject.value = value;
    }
    const newData = [...filtervalue, newObject];
    setPagination((prev) => ({
      ...prev,
      from: 0,
      size: prev?.pageSize,
      pageSize: prev?.pageSize,
      page_no: 1,
    }));
    setFilterValue(newData);
  }
};

export const handlePaginations = (
  setPagination: (value: (prev: Pagination) => Pagination) => void,
  direction: "next" | "prev"
) => {
  setPagination((previous) => {
    const newFrom =
      direction === "next"
        ? previous.from + previous.pageSize
        : previous.from - previous.pageSize;
    const newSize = direction === "next" ? previous.size : previous.size;
    const newPageNo =
      direction === "next" ? previous.page_no + 1 : previous.page_no - 1;

    return {
      ...previous,
      from: newFrom,
      size: newSize,
      page_no: newPageNo,
      pageSize: previous.pageSize,
    };
  });
};

export const handlePageSizes = (
  setPagination: (value: (prev: Pagination) => Pagination) => void,
  value: string
) => {
  const pageSize = value === "" ? 10 : parseInt(value);

  setPagination((prev) => ({
    ...prev,
    pageSize,
    size: pageSize,
    from: 0,
    page_no: 1,
  }));
};

export const isValidJSON = (str: any) => {
  try {
    let value = JSON.parse(str);
    if (value) {
      return value;
    } else {
      return str;
    }
  } catch (e) {
    return false;
  }
};

export const compressImageWithCanvas = async (file: File) => {
  try {
    const options = {
      maxSizeMB: 1, // Adjust to your desired maximum size in MB
      maxWidthOrHeight: 1024, // Adjust as needed
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    throw new Error("Image compression failed");
  }
};
export const handleFileUpload = async (
  event: React.ChangeEvent<HTMLInputElement>,
  setisLoading: (loading: boolean) => void,
  setFieldValue: (field: string, value: any) => void,
  setAlert: (alert: boolean) => void,
  setErrorType: (errorType: "success" | "error" | "warning" | null) => void,
  setMessage: (message: string) => void
) => {
  setisLoading(true);
  const { files, name } = event.target;

  if (files && files.length > 0) {
    const validTypes = ["image/png", "image/jpg", "image/jpeg"];
    const fileSizeMB = files?.[0]?.size / 1024 / 1024; // Convert file size to MB
    if (!validTypes.includes(files[0].type)) {
      setisLoading(false);
      setAlert(true); // Show alert for invalid file type
      setErrorType("error");
      setMessage("Only PNG, JPG, and JPEG files are accepted.");
      return;
    }

    if (fileSizeMB > 30) {
      setisLoading(false);
      setAlert(true); // Show alert for file size limit exceeded
      setErrorType("warning");
      setMessage("File size exceeds 30 MB. Please upload a smaller file.");
      return;
    }

    try {
      const compressedFile: any = await compressImageWithCanvas(files[0]); // Set quality to 50%
      const key = `${generateUUID()}${files[0].name}`;
      const imageObject = {
        ContentType: compressedFile.type, // Use the MIME type of the compressed file
        Expires: "3000",
        Key: key,
        Bucket: process.env.REACT_APP_MEDIA_FILES,
      };

      // Upload compressed image to S3
      const formData = new FormData();
      formData.append("file", compressedFile);

      const res = await getImage(imageObject); // Fetch presigned URL
      const imgpresignurl = JSON.parse(res.body);
      const response = await fetch(imgpresignurl.uploadURL, {
        method: "PUT",
        body: compressedFile,
        headers: {
          "Content-Type": compressedFile.type,
        },
      });

      if (response.ok) {
        setFieldValue(
          name,
          `https://s3.af-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_FILES}/${key}`
        );
        setAlert(true); // Show success alert
        setErrorType("success"); // Clear any error type
        setMessage("Image uploaded successfully."); // Set success message
      } else {
        throw new Error("Failed to upload image");
      }
    } catch (error: any) {
      setAlert(true); // Show error alert
      setErrorType("error"); // Set error type
      setMessage(`Error uploading image: ${error.message}`); // Set error message
    } finally {
      setisLoading(false);
    }
  } else {
    setisLoading(false);
    setAlert(true); // Show alert for no file selected
    setErrorType("error");
    setMessage("No file selected.");
  }
};

export const handleMultipleFileUpload = async (
  event: React.ChangeEvent<HTMLInputElement>,
  setLoader: (loading: boolean) => void,
  setFieldValue: (field: string, value: any) => void,
  values: any,
  setAlert: (alert: boolean) => void,
  setErrorType: (errorType: "success" | "error" | "warning" | null) => void,
  setMessage: (message: string) => void
) => {
  setLoader(true);
  const { files, name } = event.target;

  if (files && files.length > 0) {
    const uploadPromises = Array.from(files).map(async (file) => {
      const validTypes = ["image/png", "image/jpg", "image/jpeg"];
      const fileSizeMB = files?.[0]?.size / 1024 / 1024; // Convert file size to MB

      if (!validTypes.includes(file.type)) {
        return null; // Skip this file
      }

      if (fileSizeMB > 30) {
        console.warn(`File ${file.name} exceeds 30 MB.`);
        setAlert(true); // Show alert for file size limit exceeded
        setErrorType("warning");
        setMessage(
          `File ${file.name} exceeds 30 MB. Please upload a smaller file.`
        );
        return null; // Skip this file if it's too large
      }

      try {
        // Compress the image using the canvas method (50% quality)
        const compressedFile: any = await compressImageWithCanvas(file);

        const key = `${generateUUID()}${file.name}`;
        const imageObject = {
          ContentType: compressedFile.type,
          Expires: "3000",
          Key: key,
          Bucket: process.env.REACT_APP_MEDIA_FILES,
        };

        const res = await getImage(imageObject); // Fetch presigned URL
        const imgpresignurl = JSON.parse(res.body);
        const response = await fetch(imgpresignurl.uploadURL, {
          method: "PUT",
          body: compressedFile,
          headers: {
            "Content-Type": compressedFile.type,
          },
        });

        if (response.ok) {
          return {
            type: compressedFile.type.split("/")[0],
            url: `https://s3.af-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_FILES}/${key}`,
          };
        } else {
          console.error("Failed to upload image");
          return null;
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        return null;
      }
    });

    const uploadedFiles = (await Promise.all(uploadPromises)).filter(Boolean);

    if (uploadedFiles.length > 0) {
      const currentFieldValues = values[name] || [];
      setFieldValue(name, [...currentFieldValues, ...uploadedFiles]);
      setAlert(true); // Show success alert
      setErrorType("success"); // Set success error type
      setMessage("Images uploaded successfully."); // Set success message
    } else {
      setAlert(true); // Show alert if no files were successfully uploaded
      setErrorType("warning"); // Set warning error type
      setMessage("Only PNG, JPG, and JPEG files are accepted.");
    }
  } else {
    setAlert(true); // Show alert if no files are selected
    setErrorType("warning"); // Set warning error type
    setMessage("No files selected.");
  }

  setLoader(false);
};

export const convertAWSToInputDate = (awsDateTime: any) => {
  if (!awsDateTime) return "";

  const date = new Date(awsDateTime);
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const capitalizeFirstLetter = (string: string) => {
  if (typeof string !== "string") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formattedDate = () => {
  return new Date().toISOString();
};

export const getStatus = (input: any) => {
  if (input === null || input === undefined) {
    return "Active"; // Default status if input is null or undefined
  }

  // Convert boolean values to strings for consistent processing
  if (typeof input === "boolean") {
    input = input.toString();
  }

  // Check if the string contains '#'
  const hashIndex = input.indexOf("-");

  if (hashIndex !== -1) {
    // Split the string and check the part after '#'
    const statusAfterHash = input.slice(hashIndex + 1);
    if (statusAfterHash === "false") {
      return "Disable";
    }
  } else {
    // No '#' present, directly check the string value
    if (input === "false") {
      return "Disable";
    }
  }

  return "Active";
};

export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const forceDownloadPdf = (pdfUrl: string) => {
  if (pdfUrl) {
    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary URL from the blob
        const blobUrl = window.URL.createObjectURL(blob);

        // Create a link element for downloading
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", "invoice.pdf"); // Set the download filename

        // Programmatically click the link to trigger download
        link.click();

        // Clean up the URL object after the download
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((err) => console.error("Error fetching the PDF:", err));
  } else {
    console.error("PDF URL is not available.");
  }
};

export const exportToExcel = (
  arrayData: { [x: string]: any }[],
  fieldsToExport: any,
  customHeaders: { [x: string]: any },
  exportName: any
) => {
  const toReadableHeader = (key: string) => {
    return (
      customHeaders[key] ||
      key
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
        .toUpperCase()
    );
  };

  const keys = fieldsToExport;
  const headers = keys.map(toReadableHeader);

  const data = [
    headers,
    ...arrayData.map((item: { [x: string]: any }) =>
      keys.map((key: string | number) => item[key])
    ),
  ];

  const ws = XLSX.utils.aoa_to_sheet(data);

  const columnWidths = keys.map((_: any, colIndex: string | number) => {
    const maxLength = Math.max(
      ...data.map((row) =>
        row[colIndex] ? row[colIndex].toString().length : 0
      ),
      headers[colIndex].length
    );
    return { width: maxLength + 2 };
  });

  ws["!cols"] = columnWidths;

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  const now = new Date();

  const monthName = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(now);
  const day = now.getDate().toString().padStart(2, "0");
  const year = now.getFullYear();
  const dateString = `${monthName}_${day}_${year}`;

  let hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;

  const timeString = `${hours}:${minutes} ${period}`;
  const fileName = `${exportName}_${dateString}_${timeString.replace(/ /g, "-")}.xlsx`;

  XLSX.writeFile(wb, fileName);
};
